import { createStore } from 'vuex';
import i18n from '../i18n';
import user from '@/store/user';
import { useToast } from 'vue-toastification';
import { handleAxiosError, justNumbers } from '@/helpers';

export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' }
        ],

        pieOptions: {
            chart: {},
            legend: { position: 'bottom' },
            labels: []
        },

        barOptions: {
            chart: { toolbar: { show: false } },
            dataLabels: {
                enabled: true
            },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            colors: ['#5c1ac3', '#ffbb44'],
            dropShadow: { enabled: true, opacity: 0.3, blur: 1, left: 1, top: 1, color: '#515365' },
            plotOptions: { bar: { horizontal: false, columnWidth: '55%', borderRadius: 10 } },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                markers: { width: 12, height: 12 },
                itemMargin: { horizontal: 0, vertical: 8 }
            },
            grid: { borderColor: '#e0e6ed' },
            xaxis: {
                categories: [],
                axisBorder: { show: true, color: '#e0e6ed' }
            },
            yaxis: {
                tickAmount: 6
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.3,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100]
                }
            },
            tooltip: {
                theme: 'light',
                y: {
                    formatter: function(val) {
                        return val;
                    }
                }
            }
        }
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        }
    },
    actions: {
        async getCep({ dispatch, commit }, cep) {
            try {
                const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`, {
                    headers: { Authorization: null }
                });

                if (data?.erro) {
                    const toast = useToast();
                    toast.warning('CEP não encontrado.');
                    return null;
                }

                return data;
            } catch (e) {
                throw e;
            }
        },
        
        async getCnpj({ dispatch, commit }, cnpj) {
            try {
                cnpj = justNumbers(cnpj);
                const { data } = await axios.get(`https://cnpj.deppes.com/cnpj/${cnpj}`, {
                    headers: { Accept: 'Application/json' }
                });

                return data;
            } catch (e) {
                handleAxiosError(e);
                return null;
            }
        }
    },
    modules: {
        user
    }
});
