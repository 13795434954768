export default {
    namespaced: true,
    state: () => ({
        user: null,
        token: null
    }),

    mutations: {
        setUser(state, user) {
            state.user = user;
            if (user === null) {
                localStorage.removeItem('_user');
            } else {
                localStorage.setItem('_user', JSON.stringify(user));
            }
        },

        setToken(state, token) {
            state.token = token;
            if (token === null) {
                localStorage.removeItem('_token');
            } else {
                localStorage.setItem('_token', token);
            }
        }
    },

    getters: {
        initialLetters(state) {
            if (state.user) {
                const dataName = state.user.name.split(' ');
                return `${dataName[0].charAt(0)}${dataName[dataName.length - 1].charAt(0)}`.toUpperCase();
            }

            return '';
        }
    },

    actions: {
        async login({ dispatch, commit }, credentials) {
            try {
                const { data } = await axios.post('login', credentials);
                if (data.user.update_password) {
                    window.location.href = '/redefinir-senha/' + data.user.remember_token + '?email=' + data.user.email;
                } else {
                    commit('setToken', data.token);
                    commit('setUser', data.user);
                    window.location.href = '/';
                }
            } catch (e) {
                throw e;
            }
        },

        async logout({ dispatch, commit }) {
            try {
                await axios.get('logout');
                commit('setToken', null);
                commit('setUser', null);
                window.location.href = '/login';
            } catch (e) {
                commit('setToken', null);
                commit('setUser', null);
                window.location.href = '/login';
            }
        }
    }
};
