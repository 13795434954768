import store from '@/store';
import moment from 'moment';

export const handleAxiosError = ({ response }) => {
    if (response?.status === 401) {
        store.commit('user/setUser', null);
        store.commit('user/setToken', null);

        window.location.href = '/login';
    } else if (response?.status === 500) {
        Swal.fire('Falha interna', 'Aconteceu um erro inesperado, nossa equipe foi alertada.', 'error');
    } else if (response?.status === 404) {
        Swal.fire('Ooops', 'Objeto não encontado.', 'warning');
    } else if (response?.status === 400) {
        Swal.fire('Ação Negada', response.data.message, 'warning');
    } else if (response?.status === 429) {
        Swal.fire('Muitas tentativas', 'Foram feitas muitas tentativas, aguarde 3 minutos para tentar novamente.', 'warning');
    }
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const limitString = (str, length) => {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    }

    return str;
};


export const getParameterUrl = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const onModalDelete = (id, name) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger me-3',
            cancelButton: 'btn btn-default'
        },

        buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
        title: 'Tem certeza?',
        html: `Deseja realmente deletar: <span class='text-danger'>${name}?</span>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!'
    });

};

export const modalConfirm = (message) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger'
        },

        buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
        title: 'Tem certeza?',
        html: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
    });
};

export const initTooltip = () => {
    setTimeout(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => {
            const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
            tooltipTriggerEl.addListener('click', () => {
                tooltip.hide();
            });

            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, 10);
};

export const initPopover = () => {
    setTimeout(() => {
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
    }, 10);
};

export const justNumbers = (text) => {
    if (text !== null) {
        const regex = /\d+/g;
        return text.match(regex)?.join('');
    } else {
        return text;
    }
};

export const strAscii = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9\s]/g, '');
};

export const formatDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY');
    }

    return date;
};

export const formatDateTime = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY hh:mm');
    }

    return date;
};

export const getGender = (gender) => {
    if (gender === 'M') {
        return 'Masculino';
    }
    if (gender === 'F') {
        return 'Feminino';
    }
    if (gender === 'N') {
        return 'Não binário';
    }

    return gender;
};

export const getMaritalStatus = (gender) => {
    if (gender === 'SOLTEIRO') {
        return 'Solteiro';
    }
    if (gender === 'CASADO') {
        return 'Casado';
    }
    if (gender === 'SEPARADO') {
        return 'Separado';
    }
    if (gender === 'DIVORCIADO') {
        return 'Divorciado';
    }
    if (gender === 'UNIAO_ESTAVEL') {
        return 'União Estável';
    }
    if (gender === 'VIUVO') {
        return 'Viúvo';
    }

    return gender;
};

export const formatTime = (date) => {
    return moment(date).format('HH:mm');
};

export const generateId = (value, index = Math.floor(Math.random() * (9999999 - 10 + 1)) + 10) => {
    return strAscii(value).toLowerCase().replaceAll(' ', '_') + '_' + index;
};

export const formatPercent = (number) => {
    if (number !== null || number !== undefined) {
        return new Intl.NumberFormat('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }).format(number) + '%';
    }

    return number;
};

export const formatMoney = (number) => {
    if (number !== null || number !== undefined) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2
        }).format(number);
    }

    return number;
};

export const calculateLinearTrendLine = (x, y) => {
    const n = x.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumX2 = 0;

    for (let i = 0; i < n; i++) {
        sumX += x[i];
        sumY += y[i];
        sumXY += x[i] * y[i];
        sumX2 += x[i] * x[i];
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;
    const trendLine = [];

    for (let i = 0; i < n; i++) {
        trendLine.push({ x: parseFloat((x[i]).toFixed(2)), y: parseFloat((slope * x[i] + intercept).toFixed(2)) });
    }

    return trendLine;
};

export const initialsName = (name) => {
    if (name) {
        const dataLastName = name.split(' ');
        const lastName = dataLastName[dataLastName.length - 1];
        return name.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }

    return name;
};

export const generateStrongPassword = (length) => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const symbolChars = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    const allChars = uppercaseChars + lowercaseChars + numberChars + symbolChars;

    let password = '';
    for (let i = 0; i < length; i++) {
        password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    return password;
};

export const mask = (value, mask) => {
    value = value.toString();

    let maskedValue = '';
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        const maskChar = mask[i];

        if (maskChar === '#') {
            maskedValue += value[valueIndex] || '';
            valueIndex++;
        } else {
            maskedValue += maskChar;
        }
    }

    return maskedValue;
};
